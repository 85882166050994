import { PUBLIC_SESSION_ID_COOKIE_KEY } from 'shared/constants'
import type { AgnosticSession } from 'shared/types'

let sessionLoader: Promise<AgnosticSession | undefined> | undefined
export function useSession() {
  const session = useState('agsf-sess', () => {
    return {
      isGuest: true,
      isAnonymous: false,
      isRegistered: false,
      customer: undefined,
      softCustomer: undefined,
      cart: undefined,
      wishlist: undefined
    } as AgnosticSession
  })
  const sessionLoaded = useState('agsf-sess-load', () => false)
  const userLoading = useState('agsf-sess-user', () => true)

  const api = useEcommerce()
  const { $i18n: i18n }: { $i18n: any } = useNuxtApp()

  const error = ref('')
  function getDefaultCountry() {
    return (
      i18n.locales.value.find((locale: any) => locale.code === i18n.locale.value)?.iso?.toUpperCase() || i18n.country
    ).slice(-2)
  }
  async function load() {
    if (process.server) {
      throw Error('Not allowed to load session on server side')
    }
    try {
      const hasSession =
        document.cookie.includes(`${PUBLIC_SESSION_ID_COOKIE_KEY}=`) ||
        document.cookie.includes(`${PUBLIC_SESSION_ID_COOKIE_KEY}:${i18n.locale.value}=`)

      if (!hasSession) {
        setTimeout(() => {
          session.value = {
            isGuest: true,
            isRegistered: false,
            isAnonymous: false,
            cart: undefined,
            customer: undefined,
            wishlist: undefined,
            softCustomer: undefined
          }

          sessionLoaded.value = true
          userLoading.value = false
          error.value = ''
        })

        return
      }

      const result = await api.getSession()
      session.value = result
      sessionLoaded.value = true
      userLoading.value = false
      error.value = ''

      EventBus.removeAllListeners('sessionExpired')
      EventBus.on('sessionExpired', async () => {
        session.value = await api.getSession()
        sessionLoaded.value = true
        userLoading.value = false
        error.value = ''

        showError({ statusCode: 440, name: 'ExpirationError' })
      })

      return result
    } catch (err) {
      error.value = String(err)
    }
  }

  return {
    error,
    userLoading: userLoading,
    sessionLoaded: computed(() => sessionLoaded.value),
    session: computed(() => session.value),
    updateSession(newSession: Readonly<Partial<AgnosticSession>>) {
      if (process.client) {
        // @ts-expect-error
        session.value = {
          ...session.value,
          ...{ cart: undefined, wishlist: undefined, customer: undefined, softCustomer: undefined },
          ...newSession
        }
      } else {
        throw Error('Not allowed to update session on server side')
      }
    },
    getDefaultCountry,
    async load() {
      if (process.server) {
        throw Error('Not allowed to load session on server side')
      }

      if (sessionLoader) {
        return await sessionLoader
      }
      sessionLoader = load()
      return await sessionLoader
    },
    async forceLoad() {
      return await api.getSession()
    }
  }
}
