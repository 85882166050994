/* eslint-disable indent */
import type { EcommerceApi } from 'shared/types/apiTypes'
import { toRaw } from 'vue'
const getRequestEndpointList = [
  'getProduct',
  'getSearchFacets',
  'getContent',
  'getContentAssets',
  'getContents',
  'getHierarchy'
]

export function useEcommerce() {
  const config = useRuntimeConfig()
  const locale = useNuxtApp().$i18n.locale

  const api = new Proxy(
    {},
    {
      get: (target, prop, receiver) => {
        const functionName = String(prop) as keyof EcommerceApi
        if (Reflect.has(target, functionName)) {
          return Reflect.get(target, prop, receiver)
        }
        return async (...args: any[]) => {
          // const cookie = ''
          // delay to avoid run in component context
          await new Promise((resolve: any) => {
            setTimeout(resolve)
          })

          const isGetRequest = getRequestEndpointList.includes(functionName)
          const requestUrl = new URL(
            (process.client ? config.public.bffBaseUrl : config.ssrBffBaseUrl) + `ecommerce/${functionName}`,
            process.client ? window.location.origin : undefined
          )

          requestUrl.searchParams.append('locale', locale.value ?? '')

          if (isGetRequest) {
            args.forEach((arg) => {
              requestUrl.searchParams.append('body', JSON.stringify(arg))
            })
          }

          const { data, error } = await useAsyncData('/ecommerce/' + functionName + '/' + JSON.stringify(args), () => {
            return $fetch(requestUrl.toString(), {
              keepalive: true,
              credentials: 'include',
              body: isGetRequest ? undefined : JSON.stringify(args),
              method: isGetRequest ? 'GET' : 'POST'
            })
          })

          if (error.value) {
            return {
              ok: false,
              error: {
                __general__: 'network.error'
              }
            }
          }

          const response = toRaw(data.value) as any

          if (process.client && response && response.error) {
            const { __sidexp__: isSessionExpired, __maintenance__: isMaintenanceMode } = response.error
            if (isMaintenanceMode) {
              window.location.href = '/maintenance'
            }
            if (isSessionExpired) {
              EventBus.emit('sessionExpired')
            }
          }

          return response
        }
      }
    }
  )
  return api as EcommerceApi
}
