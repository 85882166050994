import EventEmitter from 'eventemitter3'
import { AsfPosition, AsfSetTimeout } from '@ui/types'

export const EventBus = new EventEmitter()

export const timeout = (fn: () => void, time = 0) => {
  let timer: AsfSetTimeout | null = setTimeout(() => {
    if (fn) {
      fn()
    }

    timer = null
  }, time)

  return () => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  }
}

export const getScrollPosition = (position: Exclude<AsfPosition, 'right' | 'bottom'>) => {
  const { body, documentElement } = document
  switch (position) {
    case 'top':
      return window.scrollY || documentElement.scrollTop || body.scrollTop
    case 'left':
      return window.scrollX || documentElement.scrollLeft || body.scrollLeft
    default:
      return 0
  }
}

export const getElementCoordinates = (elem: Element | null) => {
  if (!elem) {
    return null
  }

  const rect = elem.getBoundingClientRect()
  const { body, documentElement } = document
  const scroll = {
    top: getScrollPosition('top'),
    left: getScrollPosition('left')
  }
  const client = {
    top: documentElement.clientTop || body.clientTop || 0,
    left: documentElement.clientLeft || body.clientLeft || 0
  }

  return {
    top: rect.top + scroll.top - client.top,
    left: rect.left + scroll.left - client.left
  }
}

export const getElementOffsetSizes = (elem: HTMLElement | null) => {
  if (!elem) return null

  return {
    width: elem.offsetWidth,
    height: elem.offsetHeight
  }
}

export const getIsRealMouseEvent = (event: MouseEvent) => event.clientX && event.clientY

type AddListener<T> = {
  target?: (Window & typeof globalThis) | Document | Element
  options?: boolean | AddEventListenerOptions
  handler: (...args: any) => void
  type: T
}

type RemoveListener = () => void
export const addEventListenerUtil = <T extends string>({
  target = window,
  type,
  handler,
  options
}: AddListener<T>): RemoveListener => {
  if (!type || !handler) {
    throw Error('provide type and/or handler')
  }

  target.addEventListener(type, handler, options)

  return () => {
    target.removeEventListener(type, handler, options)
  }
}

export const delay = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const waitUntil = async (func: Function, timeoutMS = 5000) => {
  const tickMs = 200
  let attempts = Math.round(timeoutMS / tickMs)
  let done = false

  while (!done && attempts) {
    done = await func()
    attempts--
    await delay(tickMs)
  }

  return done
}
